.libutton {
display: flex;
flex-direction: column;
justify-content: center;
padding: 7px;
text-align: center;
outline: none;
text-decoration: none !important;
color: #ffffff !important;
width: 200px;
height: 32px;
border-radius: 16px;
background-color: #0A66C2;
font-family: "SF Pro Text", Helvetica, sans-serif;
}


img.avatar_profile {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 50%;
    float: left;
    margin: 0 10px 10px 0;
}

footer {

    .libutton {
        float: left;
        margin-right: 20px;
    }

    .page-link {
        margin: 0 20px 0 0;
    }
    .footer-col-wrapper {
        .newsletter {
            margin-top: 200px;
        }
        .newsletter-text {
            font-size: small;
        }
    }
}
    